import "./styles.scss"

import React from "react"

const Content = () => {
  return (
    <section className="about-content">
      <div className="container-fluid">
        <h3>
          Trust Investment Projekt 14 to spółka z ograniczoną odpowiedzialnością
          należąca do Grupy Trust Investment – uznanego, kieleckiego dewelopera,
          który realizuje projekty deweloperskie w sektorze nieruchomości
          mieszkaniowych i komercyjnych na terenie całej Polski.
        </h3>
        <div className="row">
          <div className="col-xl-4 col-md-6">
            <p>
              Wychodząc naprzeciw rosnącemu zainteresowaniu naszych Klientów we
              współtworzeniu naszych inwestycji, a także poszukując dodatkowych
              form finansowania realizacji ambitnego projektu deweloperskiego,
              inwestycji Sky Trust etap II, przygotowaliśmy rozwiązanie
              pozwalające zainwestować naszym Klientom środki poprzez udzielenie
              pożyczki inwestycyjnej spółce deweloperskiej realizującej to
              przedsięwzięcie - Trust Investment Projekt 14.
            </p>
          </div>
          <div className="col-xl-7 offset-xl-1 col-md-6">
            <div
              className="about-content__image"
              style={{
                backgroundImage: `url('${
                  require("assets/images/about/about-content-image.jpg").default
                }')`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
